import React, { createContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import config from 'config';
import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider, signInWithEmailAndPassword, signInWithPopup, signOut } from 'firebase/auth';
import PropTypes from 'prop-types';
import { createRelatedUser, getUser } from 'utils/firebaseCollection/usersCollection';
import { fDateTimeByTimeZone } from 'utils/formatTime';

import useLocalStorage from 'hooks/useLocalStorage';

const { firebase: firebaseConfig } = config;

const AuthContext = createContext({});
export const AuthProvider = ({ children }) => {
  const auth = getAuth(initializeApp(firebaseConfig));
  // const [user, setUser] = useState(null); // todo: maybe need to create with localhost
  const [user, setUser] = useLocalStorage('user', null);
  const [authToken, setAuthToken] = useLocalStorage('token', null);
  const navigate = useNavigate();
  const { defaultPath, defaultProtectedPath } = config;

  const setUserAuth = (data) => {
    setUser(data);
    navigate(defaultProtectedPath, { replace: true });
  };

  const setUserData = (data) => {
    const {
      uid,
      photoURL,
      phoneNumber,
      isAnonymous,
      emailVerified,
      email,
      displayName,
      metadata: { createdAt, lastLoginAt },
    } = data;
    getUser(uid).then((userData) => {
      if (userData === null) {
        createRelatedUser(uid, {
          access: 'guest',
          role: null,
          gradeId: null,
          displayName,
          photoURL,
          lastLoginAt: fDateTimeByTimeZone(Number(lastLoginAt)),
          createdAt: fDateTimeByTimeZone(Number(createdAt)),
          email,
          emailVerified,
          phoneNumber,
          isAnonymous,
        }).then((userDataObj) => {
          setUserAuth(userDataObj);
        });
      } else {
        setUserAuth(userData);
      }
    });
  };

  const signInWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        // The signed-in user info.
        const userData = result.user;
        setAuthToken(token);
        setUserData(userData);
        // ...
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const { email } = error.customData;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        // ...
        console.error({ errorCode, errorMessage, email, credential });
      });
  };

  // call this function when you want to authenticate the user
  const login = async ({ email, password }) => {
    // remember - as an attribute
    signInWithEmailAndPassword(auth, email, password)
      .then(({ user: userData }) => {
        // Signed in
        setUserData(userData);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;

        console.error({ errorCode, errorMessage });
        // ..
      });
  };

  const userCheck = () => {
    if (user !== null) {
      navigate(defaultProtectedPath, { replace: true });
    }
  };

  const isSigned = () => {
    if (user !== null) {
      return true;
    }
    return false;
  };

  // call this function to sign out logged in user
  const logout = () => {
    signOut(auth)
      .then(() => {
        // Sign-out successful.
        setUser(null);
        navigate(defaultPath, { replace: true });
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;

        console.error({ errorCode, errorMessage });
      });
  };

  const value = useMemo(
    () => ({
      user,
      authToken,
      login,
      logout,
      userCheck,
      signInWithGoogle,
      auth,
      isSigned,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user, authToken]
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthContext;
