import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';

import { CircularProgress } from '@mui/material';
import { getFeedbacks } from 'utils/firebaseCollection/feedbackCollection';
import { getOvertimes } from 'utils/firebaseCollection/overtimesCollection';

import { clockifyFetcher } from './clockifyCommon';
import { peopleForceFetcher } from './peopleForceCommon';
import { timeFormatToSeconds } from './workWithTime';

const GlobalDataContext = createContext({
  employees: [],
  leaveTypes: [],
});

function fetchEmployeesData() {
  return peopleForceFetcher({ endpoint: `/employees?page=1` }).then(({ data, metadata }) => {
    const {
      pagination: { pages },
    } = metadata;
    const employeesRequests = [];
    for (let page = 2; page <= pages; page += 1) {
      employeesRequests.push(
        peopleForceFetcher({ endpoint: `/employees?page=${page}` }).then(({ data: employeesData }) => {
          return employeesData;
        })
      );
    }
    return Promise.all(employeesRequests).then((employeesDataValues) => {
      return Array.prototype.concat
        .apply(data, employeesDataValues)
        .map(({ id, full_name: fullName, email, avatar_url: avatarUrl }) => ({ id, fullName, email, avatarUrl }));
    });
  });
}

function fetchLeaveRequests() {
  return peopleForceFetcher({ endpoint: `/leave_requests?page=1` }).then(({ data, metadata }) => {
    const {
      pagination: { pages },
    } = metadata;
    const leaveRequestsRequests = [];
    for (let page = 2; page <= pages; page += 1) {
      leaveRequestsRequests.push(
        peopleForceFetcher({ endpoint: `/leave_requests?page=${page}` }).then(({ data: leaveRequestsData }) => {
          return leaveRequestsData;
        })
      );
    }
    return Promise.all(leaveRequestsRequests).then((leaveRequestsValues) => {
      return Array.prototype.concat.apply(data, leaveRequestsValues);
    });
  });
}

function fetchLeaveTypes() {
  return peopleForceFetcher({ endpoint: `/leave_types?page=1` }).then(({ data, metadata }) => {
    const {
      pagination: { pages },
    } = metadata;
    const leaveTypesRequests = [];
    for (let page = 2; page <= pages; page += 1) {
      leaveTypesRequests.push(
        peopleForceFetcher({ endpoint: `/leave_types?page=${page}` }).then(({ data: leaveTypesData }) => {
          return leaveTypesData;
        })
      );
    }
    return Promise.all(leaveTypesRequests).then((leaveTypesValues) => {
      return Array.prototype.concat.apply(data, leaveTypesValues);
    });
  });
}

function fetchClockifyUsers() {
  return clockifyFetcher({ endpoint: `/users?page-size=5000`, isWorkspace: true }).then((data) => {
    return (
      data
        // .filter(({ status }) => status === 'ACTIVE')
        .map(({ id, email }) => {
          return { id, email };
        })
    );
  });
}

function fetchUsersOvertimes() {
  return getOvertimes().then((data) => {
    return data.map(({ id, dates }) => {
      const overtimes = dates.map(({ comment, date, overtime }) => {
        return { date, overtime, comment, amount: timeFormatToSeconds(overtime) };
      });
      return { id, overtimes };
    });
  });
}

function fetchUsersFeedbacks() {
  return getFeedbacks().then((data) => {
    return data.map(({ id, feedbacks, createdAt, updatedAt }) => {
      const lastFeedback = updatedAt === null ? createdAt : updatedAt;
      return { id: Number(id), feedbacks: { items: feedbacks, lastFeedback } };
    });
  });
}

export function GlobalDataProvider(props) {
  const [employees, setEmployees] = useState([]);
  const [leaveTypes, setLeaveTypes] = useState([]);
  useEffect(() => {
    let isSubscribed = true;
    const fetchData = () => {
      if (isSubscribed) {
        return Promise.all([
          fetchEmployeesData().then((data) => ['employeesData', data]),
          fetchLeaveTypes().then((data) => ['leaveTypesData', data]),
          fetchLeaveRequests().then((data) => ['leaveRequestsData', data]),
          fetchClockifyUsers().then((data) => ['clockifyUsersData', data]),
          fetchUsersOvertimes().then((data) => ['usersOvertimesData', data]),
          fetchUsersFeedbacks().then((data) => ['usersFeedbacksData', data]),
        ])
          .then((values) => {
            return Object.fromEntries(values);
          })
          .then(
            ({
              leaveTypesData,
              leaveRequestsData,
              employeesData,
              clockifyUsersData,
              usersOvertimesData,
              usersFeedbacksData,
            }) => {
              setLeaveTypes(leaveTypesData);

              setEmployees(
                employeesData.map((items) => {
                  const leaves = leaveRequestsData
                    .filter(({ employee_id: employeeId }) => items.id === employeeId)
                    .filter(({ state }) => state === 'approved')
                    .map(({ amount, ends_on: endsOn, entries, leave_type: leaveType, starts_on: startsOn, state }) => ({
                      amount,
                      endsOn,
                      entries,
                      leaveType,
                      startsOn,
                      state,
                    }));

                  const clockifyUser = clockifyUsersData.filter(({ email }) => email === items.email)[0];

                  const userOvertime = usersOvertimesData.filter(({ id }) => id === items.id)[0];

                  const userFeedbacks = usersFeedbacksData.filter(({ id }) => id === items.id)[0];

                  return {
                    ...items,
                    leaves,
                    clockifyUserId: clockifyUser === undefined ? null : clockifyUser.id,
                    overtimes: userOvertime === undefined ? null : userOvertime.overtimes,
                    feedbacks: userFeedbacks === undefined ? null : userFeedbacks.feedbacks,
                  };
                })
              );
            }
          );
      }
      return null;
    };

    fetchData().catch(console.error);

    // eslint-disable-next-line no-return-assign
    return () => (isSubscribed = false);
  }, []);

  const value = useMemo(() => ({ employees, leaveTypes }), [employees, leaveTypes]);

  if (employees.length === 0 && leaveTypes.length === 0) {
    return (
      <div>
        Loading the global context
        <CircularProgress color="primary" />
      </div>
    );
  }

  return <GlobalDataContext.Provider value={value} {...props} />;
}

export function useGlobalData() {
  const context = useContext(GlobalDataContext);
  if (!context) throw new Error('Need to wrap GlobalDataProvider');
  return context;
}
