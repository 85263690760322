import { initializeApp } from 'firebase/app';
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  query,
  setDoc,
  updateDoc,
  where,
} from 'firebase/firestore';

import config from '../../config';
import { fDateTimeByTimeZone } from '../formatTime';

const { firebase: firebaseConfig } = config;

function dataArray(querySnapshot) {
  const items = [];
  querySnapshot.forEach((document) => {
    items.push({
      id: document.id,
      ...document.data(),
    });
  });
  return items;
}

class FirebaseClass {
  constructor(path) {
    this.initialize();
    this.path = path;
    this.collection = collection(this.db, this.path);
  }

  initialize() {
    const app = initializeApp(firebaseConfig);
    this.db = getFirestore(app);
  }

  documentReference(id) {
    return doc(this.db, this.path, id.toString());
  }

  documents(...args) {
    const constraints = Array.from(args).map(({ field, op, value }) => {
      return where(field, op, value);
    });
    const collectionObject = query(this.collection, ...constraints);
    return getDocs(collectionObject)
      .then((response) => {
        return dataArray(response);
      })
      .catch((e) => {
        console.error(e);
      });
  }

  document(id) {
    const documentReference = this.documentReference(id);
    return getDoc(documentReference)
      .then((response) => {
        const data = response.data();
        if (data !== undefined) {
          return {
            id,
            ...data,
          };
        }
        return null;
      })
      .catch((e) => {
        console.error(e);
      });
  }

  createUniqDocument(id, data) {
    const request = {
      createdAt: fDateTimeByTimeZone(Date.now()),
      updatedAt: null,
      deletedAt: null,
      ...data,
    };
    return setDoc(this.documentReference(id), request)
      .then(() => {
        return {
          id,
          ...request,
        };
      })
      .catch((e) => {
        console.error(e);
      });
  }

  createDocument(data) {
    const request = {
      createdAt: fDateTimeByTimeZone(Date.now()),
      updatedAt: null,
      deletedAt: null,
      ...data,
    };
    return addDoc(this.collection, request).then((response) => {
      return {
        id: response.id,
        ...request,
      };
    });
  }

  update(id, data) {
    const documentReference = this.documentReference(id);
    return updateDoc(documentReference, {
      ...data,
    }).then(() => {
      return this.document(id);
    });
  }

  updateDocument(id, data) {
    return this.update(id, {
      updatedAt: fDateTimeByTimeZone(Date.now()),
      deletedAt: null,
      ...data,
    });
  }

  deleteDocument(id) {
    return this.update(id, {
      deletedAt: fDateTimeByTimeZone(Date.now()),
    });
  }

  removeDocument(id) {
    return this.document(id).then((response) => {
      const documentReference = this.documentReference(id);
      return deleteDoc(documentReference).then(() => {
        return response;
      });
    });
  }
}
export default FirebaseClass;
