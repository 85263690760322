import React from 'react';
import { useSelector } from 'react-redux';

import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import NavigationScroll from 'layout/NavigationScroll';
import themes from 'themes';

import useAuth from './hooks/useAuth';
import { PrivateRoutes, PublicRoutes } from './routes';
import { GlobalDataProvider } from './utils/GlobalDataProvider';

const App = () => {
  const customization = useSelector((state) => state.customization);
  const { isSigned } = useAuth();
  return (
    <GlobalDataProvider>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={themes(customization)}>
          <CssBaseline />
          <NavigationScroll>{!isSigned() ? <PublicRoutes /> : <PrivateRoutes />}</NavigationScroll>
        </ThemeProvider>
      </StyledEngineProvider>
    </GlobalDataProvider>
  );
};

export default App;
