import FirebaseClass from 'utils/firebaseCollection/firebaseClass';

const overtimesCollection = new FirebaseClass('overtimes');

/**
 *
 * @param overtime
 * @param comment
 * @param userId
 * @param date
 * @returns {Promise<firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>>}
 */
export function setOvertime({ overtime, comment, userId, date }) {
  return overtimesCollection
    .doc(`${userId}`)
    .get()
    .then((doc) => {
      if (doc.exists) {
        const { dates } = doc.data();
        if (dates !== undefined) {
          if (dates.find((day) => day.date === date) === undefined) {
            dates.push({ overtime, comment, date });
            return createOvertime(userId, { dates });
          }
          return dates;
        }
      }
      return createOvertime(userId, { dates: [{ overtime, comment, date }] });
    });
}

/**
 *
 * @returns {Promise<(*&{id: *})[]>}
 */
export function getOvertimes() {
  return overtimesCollection
    .get()
    .then((querySnapshot) => {
      return querySnapshot.docs.map((doc) => {
        return { id: Number(doc.id), ...doc.data() };
      });
    })
    .then((data) => ({ data }))
    .catch((error) => error);
}

/**
 *
 * @param userId
 * @param dates
 * @returns {Promise<void>}
 */
export function createOvertime(userId, { dates }) {
  return overtimesCollection
    .doc(`${userId}`)
    .set({ dates })
    .then(() => {
      return dates;
    });
}
