import { notify } from '../helpers';
import FirebaseClass from './firebaseClass';

const FB = new FirebaseClass('overtimes');

export async function createOvertime({ name, description }) {
  FB.createDocument({ name, description }).then((data) => {
    notify(`Document ${data.name} added`);
  });
}

export async function updateOvertime(id, { name, description }) {
  FB.updateDocument(id, { name, description }).then((data) => {
    notify(`Document ${data.name} updated`);
  });
}

export function deleteOvertime(id) {
  FB.deleteDocument(id).then((data) => {
    notify(`Document ${data.name} deleted`);
  });
}

export function removeOvertime(id) {
  FB.removeDocument(id).then((data) => {
    notify(`Document ${data.name} remove`);
  });
}

export async function getOvertime(id) {
  return FB.document(id);
}

export function getOvertimes() {
  return FB.documents();
}
