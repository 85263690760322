import React from 'react';

import { Chip, Grid } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';

// markup
const UserCellOvertimeContent = ({ date, comment, amount }) => {
  return (
    <Grid container direction="column" alignItems="center" justifyContent="space-between">
      <Grid item alignItems="center">
        <Tooltip title={`${date} ${comment}`}>
          <Chip label={amount} color="primary" />
        </Tooltip>
      </Grid>
    </Grid>
  );
};

UserCellOvertimeContent.propTypes = {
  date: PropTypes.oneOfType([() => null, PropTypes.string]).isRequired,
  comment: PropTypes.oneOfType([() => null, PropTypes.string]).isRequired,
  amount: PropTypes.string.isRequired,
};

export default UserCellOvertimeContent;
