import { useMemo } from 'react';

import PropTypes from 'prop-types';

import { useGlobalData } from '../utils/GlobalDataProvider';

const feedbackTypeLabels = {
  team: 'Team Feedback',
  client: 'Client (project) Feedback',
  interview: 'Interview Feedback',
};
const feedbackEvaluationLabels = {
  1: {
    label: 'Very bad',
    color: 'error',
  },
  2: {
    label: 'Bad',
    color: 'warning',
  },
  3: {
    label: 'Neutral',
    color: 'default',
  },
  4: {
    label: 'Good',
    color: 'info',
  },
  5: {
    label: 'Very good',
    color: 'success',
  },
};

const useGetUserInfo = (id) => {
  const { employees } = useGlobalData();

  return useMemo(() => {
    let averageEvaluationMessage = '';
    let feedbacks = null;
    const userInfo = employees
      // eslint-disable-next-line no-shadow
      .filter(({ feedbacks }) => {
        return feedbacks !== null;
      })
      .filter(({ id: itemId }) => {
        return itemId.toString() === id;
      })[0];

    if (userInfo !== null) {
      feedbacks = userInfo.feedbacks.items
        // .reverse()
        .map(({ createdAt, updatedAt, feedback, feedbackType, feedbackEvaluation }) => {
          const lastFeedback = updatedAt === null ? createdAt : updatedAt;

          return {
            lastFeedback,
            feedback,
            feedbackType: feedbackType ? feedbackTypeLabels[feedbackType] : null,
            feedbackEvaluation: {
              color: feedbackEvaluation ? feedbackEvaluationLabels[feedbackEvaluation].color : null,
              label: feedbackEvaluation ? feedbackEvaluationLabels[feedbackEvaluation].label : null,
            },
          };
        });

      const evaluations = userInfo.feedbacks.items
        .filter((item) => item.feedbackEvaluation !== undefined)
        .map((item) => {
          return {
            ...item,
            feedbackEvaluation: parseInt(item.feedbackEvaluation, 10),
          };
        })
        .filter((item) => item.feedbackEvaluation > 0);

      // Calculate the sum of evaluations
      const sum = evaluations.reduce((acc, item) => acc + (item.feedbackEvaluation || 0), 0);
      // Calculate the average
      const averageEvaluation = sum / evaluations.length;
      averageEvaluationMessage = !Number.isNaN(averageEvaluation) ? ` Average Evaluation: ${averageEvaluation}` : '';
    }

    return {
      userInfo,
      feedbacks,
      averageEvaluationMessage,
    };
  }, [employees, id]);
};
useGetUserInfo.propTypes = { id: PropTypes.string.isRequired };
export default useGetUserInfo;
