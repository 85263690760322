import React from 'react';

import { Chip, Divider, Typography } from '@mui/material';
import HTMLReactParser from 'html-react-parser';
import PropTypes from 'prop-types';
import MainCard from 'ui-component/cards/MainCard';
import { makeId } from 'utils/helpers';

import useGetUserInfo from '../../hooks/useGetUserInfo';

const UserFeedbacksTable = ({ id }) => {
  const { userInfo, feedbacks, averageEvaluationMessage } = useGetUserInfo(id);

  if (userInfo === null) {
    return (
      <MainCard title="No feedbacks" content>
        <div>
          <Typography variant="subtitle2">Sorry!</Typography>
        </div>
      </MainCard>
    );
  }

  return (
    <MainCard title={`Feedbacks of ${userInfo.fullName}.${averageEvaluationMessage}`} content>
      {feedbacks.map(({ lastFeedback, feedback, feedbackType, feedbackEvaluation }) => {
        return (
          <div key={makeId()}>
            <Typography variant="subtitle2">
              Date: <strong>{lastFeedback}</strong>.
              {feedbackType ? (
                <span style={{ paddingLeft: '40px' }}>
                  Feedback type: <strong>{feedbackType}</strong>
                </span>
              ) : null}
              {feedbackEvaluation.label !== null ? (
                <span style={{ paddingLeft: '40px' }}>
                  Feedback Evaluation:{' '}
                  <Chip color={feedbackEvaluation.color} size="small" label={feedbackEvaluation.label} />
                </span>
              ) : null}
            </Typography>
            <Typography style={{ padding: '15px' }} variant="div">
              {HTMLReactParser(feedback)}
            </Typography>
            <Divider sx={{ my: 1 }} />
          </div>
        );
      })}
    </MainCard>
  );
};
UserFeedbacksTable.propTypes = {
  id: PropTypes.string.isRequired,
};
export default UserFeedbacksTable;
