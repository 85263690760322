// assets
import { IconClock, IconReport, IconUsers } from '@tabler/icons';

// constant
const icons = { IconClock, IconReport, IconUsers };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
  id: 'dashboard',
  title: 'Dashboard',
  type: 'group',
  children: [
    {
      id: 'default',
      title: 'Working Time',
      type: 'item',
      url: '/',
      icon: icons.IconClock,
      breadcrumbs: false,
    },
    {
      id: 'quarterlyReport',
      title: 'Quarterly Report',
      type: 'item',
      url: '/quarterly-report',
      icon: icons.IconReport,
      breadcrumbs: false,
    },
    {
      id: 'feedback',
      title: 'Feedbacks',
      type: 'item',
      url: '/feedback',
      icon: icons.IconUsers,
      breadcrumbs: false,
    },
  ],
};

export default dashboard;
