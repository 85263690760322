import React from 'react';

import { Typography } from '@mui/material';
import menuItem from 'menu-items';

import useAuth from 'hooks/useAuth';

import NavGroup from './NavGroup';

const MenuList = () => {
  const {
    user: { access },
  } = useAuth(); // 'admin', 'developer', 'guest', 'manager'

  return menuItem[access].map((item) => {
    switch (item.type) {
      case 'group':
        return <NavGroup key={item.id} item={item} />;
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        );
    }
  });
};
export default MenuList;
