import config from '../config';
import { methodOfEndpoint } from './common';

const {
  peopleForce: { apiKey, apiUrl },
} = config;

export function leaveStates(state) {
  // pending - default
  // approved
  // rejected
  // withdrawn
  switch (state) {
    case 'approved': {
      return 'secondary';
    }
    case 'rejected': {
      return 'orange';
    }
    case 'withdrawn': {
      return 'warning';
    }
    default: {
      return 'primary';
    }
  }
}

export function peopleForceFetcher({ endpoint, method }) {
  const obj = {
    method: methodOfEndpoint(method),
    headers: {
      'X-API-KEY': apiKey,
    },
  };
  return fetch(`${apiUrl}${endpoint}`, obj)
    .then((res) => res.json())
    .then(({ data, metadata }) => {
      return { data, metadata };
    })
    .catch((error) => error);
}
