import { notify } from '../helpers';
import FirebaseClass from './firebaseClass';
import { getGrade } from './gradeCollection';

const FB = new FirebaseClass('users');

function additionalInfo(item) {
  const { gradeId } = item;
  if (gradeId === undefined) {
    return Promise.all([item]).then((values) => {
      return {
        ...values[0],
        grade: null,
      };
    });
  }
  return Promise.all([
    item,
    getGrade(gradeId).then((gradeData) => {
      return {
        grade: gradeData,
      };
    }),
  ]).then((values) => {
    return {
      ...values[0],
      ...values[1],
    };
  });
}

export async function createUser({ requirementId, question, answer, recommendation }) {
  FB.createDocument({ requirementId, question, answer, recommendation }).then(() => {
    notify(`User created`);
  });
}

export async function createRelatedUser(
  id,
  { access, role, displayName, photoURL, lastLoginAt, createdAt, email, emailVerified, phoneNumber, isAnonymous }
) {
  FB.createUniqDocument(id, {
    access,
    role,
    displayName,
    photoURL,
    lastLoginAt,
    createdAt,
    email,
    emailVerified,
    phoneNumber,
    isAnonymous,
  }).then((data) => {
    notify(`User created`);
    return data;
  });
}

export async function updateUser(id, { access, role, displayName, photoURL, email, phoneNumber, gradeId }) {
  FB.updateDocument(id, {
    access,
    role,
    displayName,
    photoURL,
    email,
    phoneNumber,
    gradeId,
  }).then(() => {
    notify(`User updated`);
  });
}

export function deleteUser(id) {
  FB.deleteDocument(id).then(() => {
    notify(`User deleted`);
  });
}

export function removeUser(id) {
  FB.removeDocument(id).then(() => {
    notify(`User removed`);
  });
}

export async function getUser(id) {
  return FB.document(id);
}

export function getUsers() {
  return FB.documents().then((data) => {
    return Promise.all(
      data.map((item) => {
        return additionalInfo(item);
      })
    );
  });
}

export function getGuests() {
  return FB.documents(
    { field: 'access', op: '==', value: 'guest' },
    { field: 'deletedAt', op: '==', value: null }
  ).then((data) => {
    return Promise.all(
      data.map((item) => {
        return additionalInfo(item);
      })
    );
  });
}

export function getDevelopers() {
  return FB.documents(
    { field: 'access', op: '==', value: 'developer' },
    { field: 'deletedAt', op: '==', value: null }
  ).then((data) => {
    return Promise.all(
      data.map((item) => {
        return additionalInfo(item);
      })
    );
  });
}
