import React, { lazy } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import Loadable from '../ui-component/Loadable';

const Login = Loadable(lazy(() => import('views/authentication/wrappers/Login')));
const FeedbackForm = Loadable(lazy(() => import('views/feedback/Form')));

const PublicRoutes = () => {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/feedback" element={<FeedbackForm />} />
      <Route path="*" element={<Navigate to="/feedback" />} />
    </Routes>
  );
};

export default PublicRoutes;
