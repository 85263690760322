import { fDateTimeByTimeZone } from '../formatTime';
import { makeId, notify } from '../helpers';
import FirebaseClass from './firebaseClass';

const FB = new FirebaseClass('feedbacks');

export async function createFeedback({ id, feedback, label, feedbackType, feedbackEvaluation }) {
  const userFeedbacks = await getFeedback(id);
  const feedbackData = {
    id: makeId(),
    createdAt: fDateTimeByTimeZone(Date.now()),
    updatedAt: null,
    deletedAt: null,
    feedback,
    feedbackType,
    feedbackEvaluation,
  };
  if (userFeedbacks === null) {
    FB.createUniqDocument(id, { feedbacks: [feedbackData] }).then(() => {
      notify(`Feedback added for ${label}`);
    });
  } else {
    const { feedbacks } = userFeedbacks;
    feedbacks.push(feedbackData);
    FB.updateDocument(id, { feedbacks }).then(() => {
      notify(`Feedback added for ${label}`);
    });
  }
  return true;
}

export async function updateFeedback(id, { name, description }) {
  FB.updateDocument(id, { name, description }).then((data) => {
    notify(`Document ${data.name} updated`);
  });
}

export function deleteFeedback(id) {
  FB.deleteDocument(id).then((data) => {
    notify(`Document ${data.name} deleted`);
  });
}

export function removeFeedback(id) {
  FB.removeDocument(id).then((data) => {
    notify(`Document ${data.name} remove`);
  });
}

export async function getFeedback(id) {
  return FB.document(id);
}

export function getFeedbacks() {
  return FB.documents({ field: 'deletedAt', op: '==', value: null });
}
