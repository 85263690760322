const config = {
  // basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
  // like '/berry-material-react/react/default'
  basename: '/',
  defaultPath: '/feedback',
  defaultProtectedPath: '/',
  fontFamily: `'Roboto', sans-serif`,
  borderRadius: 12,
  peopleForce: {
    apiKey: process.env.REACT_APP_PEOPLEFORCE_APIKEY,
    apiUrl: `https://otakoyi.peopleforce.io/api/public/v1`,
  },
  clockify: {
    apiKey: process.env.REACT_APP_CLOCKIFY_APIKEY,
    apiUrl: `https://api.clockify.me/api/v1`,
    reportsUrl: `https://reports.api.clockify.me/v1`,
    ptoUrl: `https://reports.api.clockify.me/v1`,
    workspaceId: process.env.REACT_APP_CLOCKIFY_WORKSPACE_ID,
  },
  normativeWorkingTimeForDay: 28800, // 8.5 hours
  rowsPerPage: 10,
  firebase: {
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BACKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
  },
  holidays: [
    '2022-01-03',
    '2022-04-25',
    '2022-06-13',
    '2022-12-26',
    '2023-01-02',
    '2023-04-17',
    '2023-08-24',
    '2023-10-14',
    '2023-12-25',
  ],
  specialWorkingDays: ['2022-05-09'],
};

export default config;
