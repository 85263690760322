import { toast } from 'material-react-toastify';

/**
 *
 * @returns {string}
 */
export function makeId() {
  let text = '';
  const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  for (let i = 0; i < 10; i += 1) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }

  return text;
}

export function notify(message, type) {
  const options = {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };
  try {
    switch (type) {
      case 'error': {
        toast.error(`💩 ${message}`, options);
        break;
      }
      default: {
        toast.success(`🦄 ${message}`, options);
      }
    }
  } catch (e) {
    console.error(e);
  }
}
