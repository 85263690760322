import React, { useState } from 'react';
import InputMask from 'react-input-mask';
import Popup from 'reactjs-popup';

import { Box, Button, FormControl, FormHelperText, Grid, InputLabel, OutlinedInput } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import { Formik } from 'formik';
import PropTypes from 'prop-types';

import { setOvertime } from '../../utils/FireBaseStore';
import { secondsToTimeFormat, timeFormatToSeconds } from '../../utils/workWithTime';
import 'reactjs-popup/dist/index.css';
import UserCellOvertimeContent from './UserCellOvertimeContent';

const button = (profit, customColor = null, contained = true) => {
  const time = profit < 0 ? profit * -1 : profit;
  const color = profit < 0 ? 'error' : 'success'; // 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning',
  return (
    <Button
      disableElevation
      fullWidth
      size="small"
      type="button"
      color={customColor !== null ? customColor : color}
      variant={contained ? 'contained' : 'text'}
    >
      {secondsToTimeFormat(time)}
    </Button>
  );
};

const UserCellTotalContent = ({ data }) => {
  const theme = useTheme();
  const { duration, date, profit, info, userId } = data; // {amount, normativeTime}
  const [dayOvertime, setDayOvertime] = useState(null);
  let profitTime = profit;
  if (dayOvertime !== null) {
    profitTime = profit - timeFormatToSeconds(dayOvertime.overtime);
  }

  return (
    <Grid container direction="column">
      <Grid item alignItems="center">
        <Grid item alignItems="center">
          <Tooltip title={info}>{button(duration, 'inherit', false)}</Tooltip>
        </Grid>
        {!Number.isNaN(profitTime) ? (
          <Grid item alignItems="center">
            {profitTime >= 3600 && date !== null ? (
              <Popup trigger={button(profitTime, 'success')} position="top center" nested>
                <Formik
                  initialValues={{
                    overtime: secondsToTimeFormat(profitTime),
                    comment: '',
                    userId,
                    date,
                  }}
                  onSubmit={async (values) => {
                    await setOvertime(values).then((overtimes) => {
                      const overtimeData = overtimes
                        .filter((day) => {
                          return day.date === date;
                        })
                        .pop();
                      setDayOvertime(overtimeData);
                    });
                  }}
                >
                  {({ handleBlur, handleChange, handleSubmit, isSubmitting, values }) => (
                    <form onSubmit={handleSubmit}>
                      <Box>
                        <FormHelperText>{`Overtime of ${info}`}</FormHelperText>
                      </Box>
                      <FormControl fullWidth sx={{ ...theme.typography.customInput }}>
                        <InputLabel htmlFor="outlined-adornment-email-login">Overtime</InputLabel>
                        <InputMask
                          mask="99:59:59"
                          formatChars={{
                            9: '[0-9]',
                            5: '[0-5]',
                          }}
                          value={values.overtime}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          {(inputProps) => (
                            <OutlinedInput
                              {...inputProps}
                              id="outlined-adornment-email-login"
                              type="text"
                              name="overtime"
                              label="Overtime"
                            />
                          )}
                        </InputMask>
                      </FormControl>
                      <FormControl fullWidth sx={{ ...theme.typography.customInput }}>
                        <InputLabel htmlFor="outlined-adornment-email-login">Comment</InputLabel>
                        <OutlinedInput
                          id="outlined-adornment-email-login"
                          type="text"
                          name="comment"
                          value={values.comment}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          label="Comment"
                          inputProps={{}}
                        />
                      </FormControl>
                      <Box>
                        <Button
                          disableElevation
                          fullWidth
                          disabled={isSubmitting}
                          size="small"
                          type="submit"
                          color="primary"
                        >
                          Save
                        </Button>
                      </Box>
                    </form>
                  )}
                </Formik>
              </Popup>
            ) : (
              button(profitTime, null, false)
            )}
            {dayOvertime !== null ? (
              <UserCellOvertimeContent
                date={dayOvertime.date}
                amount={dayOvertime.overtime}
                comment={dayOvertime.comment}
              />
            ) : null}
          </Grid>
        ) : null}
      </Grid>
    </Grid>
  );
};

UserCellTotalContent.propTypes = {
  data: PropTypes.shape({
    amount: PropTypes.number.isRequired,
    duration: PropTypes.number.isRequired,
    normativeTime: PropTypes.number.isRequired,
    date: PropTypes.oneOfType([() => null, PropTypes.string]),
    profit: PropTypes.number.isRequired,
    info: PropTypes.string,
    userId: PropTypes.number.isRequired,
  }).isRequired,
};

export default UserCellTotalContent;
