import dashboard from './dashboard';
import manager from './manager';
// 'admin', 'developer', 'guest', 'manager'
const menuItems = {
  admin: [dashboard],
  manager: [manager],
  guest: [],
  developer: [],
};

export default menuItems;
