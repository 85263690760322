import React, { lazy } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { useGlobalData } from 'utils/GlobalDataProvider';

import useAuth from '../hooks/useAuth';
import { MainLayout } from '../layout';
import Loadable from '../ui-component/Loadable';
import UserFeedbacksTable from '../views/feedback/UserFeedbacksTable';

const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));
const QuarterlyReport = Loadable(lazy(() => import('views/quarterly-report/')));
const Feedbacks = Loadable(lazy(() => import('views/feedback/')));
const UserFeedbacks = Loadable(lazy(() => import('views/feedback/UserFeedbacks')));

const PrivateRoutes = () => {
  const { employees } = useGlobalData();
  const {
    user: { email, access },
  } = useAuth(); // 'admin', 'developer', 'guest', 'manager'

  if (employees.length === 0) return null;

  const { id: userId } = employees.filter((employee) => employee.email === email)[0];

  // below placed the quick solution

  switch (access) {
    case 'admin': {
      return (
        <Routes>
          <Route path="/" element={<MainLayout />}>
            <Route path="/" element={<DashboardDefault />} />
            <Route path="/quarterly-report" element={<QuarterlyReport />} />
            <Route path="/feedback" element={<Feedbacks />} />
            <Route path="/feedback/:id" element={<UserFeedbacks />} />
          </Route>
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      );
    }
    case 'manager': {
      return (
        <Routes>
          <Route path="/" element={<MainLayout />}>
            <Route path="/" element={<DashboardDefault />} />
            <Route path="/quarterly-report" element={<QuarterlyReport />} />
            <Route path="/feedback" element={<UserFeedbacksTable id={`${userId}`} />} />
          </Route>
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      );
    }
    default: {
      return (
        <Routes>
          <Route path="/" element={<MainLayout />}>
            <Route path="/" element={<UserFeedbacksTable id={`${userId}`} />} />
          </Route>
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      );
    }
  }
};

export default PrivateRoutes;
