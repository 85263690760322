import { notify } from '../helpers';
import FirebaseClass from './firebaseClass';

const FB = new FirebaseClass('grades');

export async function createGrade({ name, description }) {
  FB.createDocument({ name, description }).then((data) => {
    notify(`Document ${data.name} added`);
  });
}

export async function updateGrade(id, { name, description }) {
  FB.updateDocument(id, { name, description }).then((data) => {
    notify(`Document ${data.name} updated`);
  });
}

export function deleteGrade(id) {
  FB.deleteDocument(id).then((data) => {
    notify(`Document ${data.name} deleted`);
  });
}

export function removeGrade(id) {
  FB.removeDocument(id).then((data) => {
    notify(`Document ${data.name} remove`);
  });
}

export async function getGrade(id) {
  return FB.document(id);
}

export function getGrades() {
  return FB.documents({ field: 'deletedAt', op: '==', value: null });
}
