import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import App from 'App';
import { AuthProvider } from 'context/AuthProvider';
import { ToastContainer } from 'material-react-toastify';
import * as serviceWorker from 'serviceWorker';
import { store } from 'store';
import 'assets/scss/style.scss';
import 'material-react-toastify/dist/ReactToastify.css';

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <AuthProvider>
        <App />
      </AuthProvider>
    </BrowserRouter>
    <ToastContainer />
  </Provider>,
  document.getElementById('root')
);
serviceWorker.unregister();
