import React from 'react';

import useSWR from 'swr';

import config from '../config';
import { methodOfEndpoint } from './common';

const {
  clockify: { apiKey, apiUrl, reportsUrl, ptoUrl, workspaceId: commonWorkSpaceId },
} = config;

const setWorkspace = (workspaceId) => {
  if (workspaceId !== undefined) {
    return `/workspaces/${workspaceId}`;
  }
  return `/workspaces/${commonWorkSpaceId}`;
};

export function typeOfClockifyUrl(type) {
  switch (type) {
    case 'pto': {
      return ptoUrl;
    }
    case 'reports': {
      return reportsUrl;
    }
    default: {
      return apiUrl;
    }
  }
}

export function clockifyFetcher({ endpoint, type, method, body, isWorkspace, workspaceId }) {
  const commonUrl = typeOfClockifyUrl(type);
  const commonEndpoint = isWorkspace ? setWorkspace(workspaceId) : '';
  const obj = {
    method: methodOfEndpoint(method),
    headers: {
      'Content-Type': 'application/json',
      'X-Api-Key': apiKey,
    },
    body: JSON.stringify(body),
  };
  return fetch(`${commonUrl}${commonEndpoint}${endpoint}`, obj)
    .then((res) => res.json())
    .then((resJson) => resJson);
}

export function GetSummaryData(firstDay, lastDay) {
  const { data: summaryData, error: errorSummary } = useSWR(
    {
      endpoint: `/reports/summary`,
      type: 'reports',
      method: 'post',
      body: {
        dateRangeStart: `${firstDay}T00:00:00.000`,
        dateRangeEnd: `${lastDay}T23:59:59.000`,
        summaryFilter: {
          groups: ['USER', 'DATE'],
        },
        amountShown: 'HIDE_AMOUNT',
      },
      isWorkspace: true,
    },
    clockifyFetcher
  );

  if (errorSummary) return <div>failed to load</div>;
  if (!summaryData) return [];

  const { groupOne: summaryUsersData } = summaryData; // metadata
  return summaryUsersData !== undefined ? summaryUsersData : [];
}
