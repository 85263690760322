import config from 'config';

export function getAllDaysInMonth(year, month) {
  const date = new Date(year, month, 1);

  const dates = [];

  while (date.getMonth() === month) {
    const currentDay = new Date(date);
    const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
    const labelOption = { month: 'short', day: 'numeric' };

    dates.push({
      date: currentDay,
      formatted: currentDay.toLocaleDateString('fr-CA', options),
      label: currentDay.toLocaleDateString(window.navigator.language, labelOption),
      weekLabel: currentDay.toLocaleString(window.navigator.language, { weekday: 'short' }),
      week: currentDay.toLocaleString('en-CA', { weekday: 'short' }),
    });
    date.setDate(date.getDate() + 1);
  }
  return dates;
}

export function rangeOfDaysByMonths(year, months) {
  const days = [];
  months.forEach((month) => {
    getAllDaysInMonth(year, month).forEach((day) => {
      days.push(day);
    });
  });
  return days;
}

export function secondsToTimeFormat(secNum) {
  let hours = Math.floor(secNum / 3600);
  let minutes = Math.floor((secNum - hours * 3600) / 60);
  let seconds = secNum - hours * 3600 - minutes * 60;

  if (hours < 10) {
    hours = `0${hours}`;
  }
  if (minutes < 10) {
    minutes = `0${minutes}`;
  }
  if (seconds < 10) {
    seconds = `0${seconds}`;
  }
  return `${hours}:${minutes}:${seconds}`;
}

export function getWorkingDays(days) {
  return Array.from(
    new Set(
      days
        .filter((day) => {
          const holiday = config.holidays.find((dayOff) => dayOff === day.formatted);
          return day.formatted !== holiday;
        })
        .filter((day) => day.week !== 'Sat' && day.week !== 'Sun')
        .concat(
          days.filter((day) => {
            const specialDay = config.specialWorkingDays.find((workingDay) => workingDay === day.formatted);
            return day.formatted === specialDay;
          })
        )
    )
  );
}

export function getDayOffs(days, workingDays) {
  return Array.from(days.filter((obj) => workingDays.indexOf(obj) === -1));
}

export function rangeOfDays(month, year) {
  if (Array.isArray(month)) {
    return rangeOfDaysByMonths(year, month);
  }
  return getAllDaysInMonth(year, month);
}

export function blaBlaBla(month, year) {
  const days = rangeOfDays(month, year);
  const workingDays = getWorkingDays(days);
  const dayOffs = getDayOffs(days, workingDays);
  return {
    allDays: days,
    allDaysAmount: days.length,
    dayOffs,
    dayOffsAmount: dayOffs.length,
    workingDays,
    workingDaysAmount: workingDays.length,
    firstDay: days[0].formatted,
    lastDay: days[days.length - 1].formatted,
  };
}

/**
 *
 * @param strTime
 * @returns {number}
 */
export function timeFormatToSeconds(strTime) {
  const regex = /(?<hours>\d+):(?<minutes>[0-5]\d):(?<seconds>[0-5]\d)/;
  const {
    groups: { hours, minutes, seconds },
  } = regex.exec(strTime);
  return Number(hours) * 60 * 60 + Number(minutes) * 60 + Number(seconds);
}
